import restApi from './congressApiService'
import Constants from '@/constants'
const bookingService = {}

bookingService.getAvailability = (hotelCode, checkIn, checkOut, distribution, language) => {

    let data = {
        "hotelAvailability": {
            "searchAvail": {
                "market": Constants.SPAIN_ISO_COUNTRY,
                "checkIn": checkIn,
                "checkOut": checkOut,
                "locale": Constants.LOCALE_LANGUAGES[language],
                "destination": {
                    "hotelCodes": [hotelCode]
                },
                "rooms": distribution,
            },
        }
    }

    return restApi.post('api/booking/availability/', data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw new Error(err)
        })
}

bookingService.prebooking = (token, bookToken) => {
    let data = {
        "bookToken": bookToken,
        "token": token
    }

    return restApi.post('api/booking/prebooking/', data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw new Error(err)
        })
}

bookingService.payment = (reference, bookToken, time, travellers) => {
    let data = {
        "reference": reference,
        "bookToken": bookToken,
        "time": time,
        "remarks": "",
        "travellers": travellers
    }

    return restApi.post('api/booking/payment/', data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw new Error(err)
        })
}

bookingService.detail = (reference) => {

    return restApi.get(`api/booking-detail/${reference}/`)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw new Error(err)
        })
}


export default bookingService