export default Object.freeze({
  MAX_ROOMS_BOOKING: 4,
  MAX_PASSENGERS_BOOKING: 8,
  PASSENGER_AGE: 30,
  NO_REFUNDABLE_IDS: ['NOREEMBOLSABLE', 'OPACANRF'],
  EVENTS: 'events',
  POLARIS: 'polaris',
  PRIVACY_POLICY_URL: 'https://barcelo.eventsair.com/bcocongreso-master/privacypolicy/Site/Register',
  SPAIN_PREFIX: "34",
  SPAIN_ISO_COUNTRY: "ES",
  SPANISH_LANGUAGE_CODE: "ES",
  LOCALE_LANGUAGES: {
    EN: 'en_GB',
    ES: 'es_ES'
  },
  FORM_ERRORS: {
    REQUIRED: 'required',
    EMAIL: 'email',
    SAME_AS: 'sameAs',
    MAX_LENGTH: 'maxLength'
  }
})