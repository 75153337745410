<template>
  <div>
    <navbar :class="{ show: true }" :showLinks="false"></navbar>
    <div class="flex-container">
      <div class="topics">
      </div>
      <div class="main-text">
        <h3>{{ $t('paymentCancelled') }}</h3>

        <p>{{ $t('paymentNotCompleted') }}</p>
        <div class="links">
          <a @click="goToHotel" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
            {{ $t('goHotel') }}
          </a>
          <a @click="goToCongress" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
            {{ $t('goCongress') }}
          </a>
        </div>
      </div>
    </div>
    <contact-footer :showFooter="true"/>
    <legal-footer :showFooter="true"/>
  </div>
</template>

<script>

import Navbar from '@/components/Navbar'
import ContactFooter from '@/components/landing-page/ContactFooter'
import LegalFooter from '@/components/landing-page/LegalFooter'

import bookingService from "@/services/bookingService";

export default {
  name: "Cancelled",
  components: {
    Navbar,
    ContactFooter,
    LegalFooter,
  },
  data() {
    return {
      reference: null,
      booking: null,
    }
  },
  methods: {
    goToCongress() {
      this.$router.push({name: 'home'})
    },
    goToHotel() {
      this.$router.push({name: 'hotel-detail-page', params: {id: this.booking.hotel.id}})
    }
  },
  mounted() {
    this.reference = this.$route.params.reference
    bookingService.detail(this.reference)
        .then(data => {
          if (data.is_booked)
            this.$router.push({name: 'booking-detail', params: {reference: this.reference}})

          this.booking = data
        })
        .catch(() => this.$router.push('/404'))
  }
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: start;
  align-items: top;
  height: 60vh;
  width: 100vw;
  padding-top: 30px;
  text-align: left;
}

.main-text {
  width: 50%;
  font-size: 18px;

  h3 {
    font-size: 42px;
  }
}

.topics {
  padding-left: 50px;
  width: 30%;
}

.links {
  margin-top: 20px;
}

a.btn.btn-primary {
  line-height: 40px;
  margin-left: 20px;
  width: 170px;
  height: 40px;
  color: white;
  display: inline-block;
  background-color: var(--primary-color);
  margin-bottom: 25px;

  &:hover {
    background-color: var(--hover-color);
  }
}

@media (max-width: 768px) {

  .links {
    padding-top: 20px;
    padding-left: 0 !important;
    overflow: scroll;

    a {
      font-weight: bold;
      color: #747272;
      font-size: 14px !important;
    }
  }

  .flex-container {
    height: auto;
    width: auto;
    padding-top: 60px;
    margin: 0 20px;
    flex-direction: column;

    .topics {
      padding-left: 0;
    }

    .main-text {
      width: 100%;

      h3 {
        font-size: 22px;
      }

      p, ul {
        font-size: 15px;
      }
    }
  }

}

</style>